const firebaseConfig = {
  apiKey: "AIzaSyC7BTPaWTcRydlNVsPj_A0jhPBOlwQ9ZPE",
  authDomain: "quiklearn-9b9f3.firebaseapp.com",
  projectId: "quiklearn-9b9f3",
  storageBucket: "quiklearn-9b9f3.appspot.com",
  messagingSenderId: "17483709086",
  appId: "1:17483709086:web:de0e6c25dd7d0fc6a2998f",
  measurementId: "G-HQFQ8G1ZH6"
};

export default firebaseConfig;
